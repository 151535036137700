import React from 'react';

import logoImage from '../images/logo.png';

const Splash = () => {
    return (
        <div className='splash'>
            <img src={logoImage} alt='Large Wisteria logo' className='hero' />
            <div className='column'>
                <h2>Truly automated turf cutting for political campaigns</h2>
                <p>Wisteria helps speed up your campaign's canvassing efforts and connect to voters faster by removing the work of dividing voters into groups for volunteers to talk to.</p>
                <p>Integrating with voter CRMs including NGP VAN, Wisteria uses a specialized algorithm considering walking routes and obstructions to calculate non-overlapping and walkable routes that candidates and campaign staff can follow to systematically speak to their constituents. Instead of wasting time and money manually drawing polygons on a map, your staff can pick up their clipboards, tie their lace, and talk to the voters.</p>
                <a className='button' href='mailto:me@erikboesen.com?subject=Campaign interested in cutting turf with Wisteria'>Contact Us</a>
            </div>
        </div>
    );
};

export default Splash;
