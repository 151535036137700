import { useRef, useEffect, useState } from 'react';
import mapboxgl, { Map } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import Papa from 'papaparse';

const INITIAL_CENTER: [number, number] = [
    -77.17457,
    38.88512,
];
const INITIAL_ZOOM: number = 13.8;

function VotersMap() {
    const mapRef = useRef<Map | null>(null);
    const mapContainerRef = useRef<HTMLDivElement | null>(null);

    const [center, setCenter] = useState<[number, number]>(INITIAL_CENTER);
    const [zoom, setZoom] = useState<number>(INITIAL_ZOOM);

    const getColor = (clusterIndex: number) => {
        return 'hsl(' + 50 * clusterIndex + ' 60% 60%)';
    };

    useEffect(() => {
        mapboxgl.accessToken = 'pk.eyJ1IjoiZXJpa2JvZXNlbiIsImEiOiJjbTJzaWpvOW0xcG9uMmlvcjh1Y2R2cmFnIn0.sdaD1DgwAwsNqDk_drNSDA';
        mapRef.current = new mapboxgl.Map({
            container: mapContainerRef.current!,
            center: center,
            zoom: zoom
        });

        const fetchData = async () => {
            const response = await fetch('http://localhost:8080/cache_clustered_properties.csv');
            const text = await response.text();
            Papa.parse<{ LAT: string; LONG: string; Cluster: number; }>(text, {
                header: true,
                complete: (results) => {
                    console.log(results);
                    results.data.forEach((row) => {
                        const lat = parseFloat(row.LAT);
                        const lng = parseFloat(row.LONG);
                        if (!isNaN(lat) && !isNaN(lng)) {
                            new mapboxgl.Marker({ color: getColor(row.Cluster) })
                                .setLngLat([lng, lat])
                                .addTo(mapRef.current!);
                        }
                    });
                }
            });
        };

        fetchData(); // Call the fetch function

        mapRef.current.on('move', () => {
            const mapCenter = mapRef.current!.getCenter();
            const mapZoom = mapRef.current!.getZoom();

            setCenter([mapCenter.lng, mapCenter.lat]);
            setZoom(mapZoom);
        });

        return () => {
            mapRef.current?.remove();
        };
    }, []);

    const handleButtonClick = () => {
        mapRef.current?.flyTo({
            center: INITIAL_CENTER,
            zoom: INITIAL_ZOOM
        });
    };

    return (
        <>
            <div id='map-container' ref={mapContainerRef} />
        </>
    );
}

export default VotersMap;
